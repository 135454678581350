@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  .landingFooter {
    padding-top: 150px;
    padding-bottom: 35px;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
      padding-bottom: 40px;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 767px) {
      max-width: 390px;
      align-items: flex-start;
    }

    .logo {
      flex: 0 0 auto;
      width: 187px;
      height: 30px;
      background: url(/images/logo.svg) no-repeat 0 50%;
      background-size: contain;
      position: relative;
      z-index: 101;
      margin-bottom: 60px;
      @media only screen and (max-width: 1023px) {
        margin-bottom: 40px;
      }
    }
    .footMenu {
      max-width: 1060px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $grey;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      .col {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        order: 1;
        @media only screen and (max-width: 767px) {
          order: 2;
        }
        a {
          margin-top: 15px;
          gap: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.45;
          text-transform: none;
          letter-spacing: normal;
          color: $white;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-decoration: none;
          &:first-child {
            margin-top: 30px;
            @media only screen and (max-width: 1023px) {
              margin-top: 20px;
            }
          }
          &:hover {
            display: inline-block;
            vertical-align: top;
            background: linear-gradient(90deg, #e571ff 0%, #ff8937 97.22%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }
      .follow {
        order: 2;
        flex: 1 1 auto;
        @media only screen and (max-width: 767px) {
          order: 1;
        }
        .social {
          margin-top: 30px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          @media only screen and (max-width: 1023px) {
            margin-top: 20px;
          }
          .icon {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $mdark;
            width: 50px;
            height: 50px;
            border-radius: 14px;
            transform: scale(1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            color: $white;
            text-decoration: none;
            z-index: 2;
            &:active {
              transform: scale(0.95);
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: $grad;
              opacity: 0;
              transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }
            svg {
              position: relative;
              z-index: 5;
            }
            &:hover:before {
              opacity: 1;
            }
          }
        }
      }
    }
    .footText {
      margin-top: 85px;
      padding-top: 35px;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      line-height: 1.4;
      color: $grey;
      justify-content: space-between;
      @media only screen and (max-width: 1023px) {
        margin-top: 60px;
        padding-top: 30px;
        gap: 0;
      }
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      .prod {
        @media only screen and (max-width: 767px) {
          font-weight: bold;
          font-size: 16px;
          color: $white;
          margin: 0 0 10px 0;
        }
      }
      .rights {
        text-align: center;
        @media only screen and (max-width: 767px) {
          font-weight: 500;
          font-size: 10px;
          line-height: 1.4;
          text-align: left;
          max-width: 200px;
        }
      }
      .links {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        @media only screen and (max-width: 767px) {
          align-items: flex-start;
          font-weight: 600;
          font-size: 12px;
          opacity: 0.9;
          margin-top: 30px;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
}

.footer {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 150px 20px 35px 20px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 1340px;
  }
}

.topPart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    margin: 0;
  }

  & > svg {
    transform: scale(1.2);
  }
}

.logoTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  margin-left: 20px;
}

.downloadAndSupport {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.support {
  margin-left: 20px;
  @media only screen and (min-width: 768px) {
    margin: 0 40px 0 0;
  }
  & > a {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 145%;
    color: #ffffff;
  }
}

.supportTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  color: #a29dba;
  margin: 0 0 2px 0;
}

.downloadLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  gap: 20px;
  width: 182.5px;
  height: 60px;
  background: #ffffff;
  border-radius: 14px;
  cursor: pointer;

  & > svg {
    flex-shrink: 0;
    transform: scale(1.2);
  }
}

.downloadTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #a29dba;
  display: block;
}

.downloadSubtitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  color: #080610;
  display: block;
}

.middlePart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 350px;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    max-width: none;
  }
}

.linksWrapper {
  &:first-of-type {
    flex: 100%;
    width: 350px;

    @media only screen and (min-width: 768px) {
      flex: initial;
    }
  }
}

.linksTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a29dba;
  margin: 0 0 30px 0;
}

.links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 160px;
}

.link {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer;
}

.socials {
  margin: 0 !important;

  & > * > a {
    height: 50px !important;
    display: inline-block;
    fill: white;
    margin-top: 20px;
  }
}

.bottomPart {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding-top: 30px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  & > div:first-of-type {
    margin-bottom: 10px;
  }

  & > div:nth-child(2) {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  & > div {
    margin-bottom: 0;
  }
}

.bottomSection1 {
  font-family: 'Gilroy';
  font-style: normal;

  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  color: #ffffff;

  @media only screen and (min-width: 768px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #a29dba;
  }
}

.bottomSection2 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #a29dba;

  text-align: left;

  @media only screen and (min-width: 768px) {
    text-align: center;
  }
}

.bottomSection3 {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: left;
  color: #ffffff;

  @media only screen and (min-width: 768px) {
    text-align: right;
  }
}
