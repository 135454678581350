@charset "utf-8";
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts_breeding/Gilroy-Medium.woff') format('woff'),
    url('../public/fonts_breeding/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts_breeding/Gilroy-SemiBold.woff') format('woff'),
    url('../public/fonts_breeding/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts_breeding/Gilroy-Bold.woff') format('woff'),
    url('../public/fonts_breeding/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts_breeding/Gilroy-ExtraBold.woff') format('woff'),
    url('../public/fonts_breeding/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

$font: Gilroy, -apple-system, 'Open Sans', system-ui, 'sans-serif';
$dark: #080610;
$dark0: rgba(8, 6, 16, 0);
$dark1: #353047;
$mdark: #1d192c;
$white: #fff;
$grey: #a29dba;
$grad: linear-gradient(90deg, #e571ff 0%, #ff8937 97.22%);
$grad2: linear-gradient(90deg, #ffc8ad 0%, #b174ff 97.22%);
$gradHover: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
  linear-gradient(90deg, #e571ff 0%, #ff8937 97.22%);
$blocks: #110e1c;
$borders: #2e2942;
$binance: #f3bb2e;
$windows: #2f82ff;
$macos: #1e97f8;
$input-bg: #0e0c18;
$input-error: #ff3f56;
$input-border: rgba(255, 255, 255, 0.1);

@mixin mainContainer {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
